
import User from "@/interfaces/User";
import {
  CallParticipant,
  voiceChannelModule,
} from "@/store/modules/voiceChannels";
import { PropType } from "vue";
import CallTemplate from "./ExpandedCallTemplate.vue";
import BigPreview from "./BigPreview.vue";
import { joinCall, leaveCall } from "@/services/voiceService";
import { PopoutsModule } from "@/store/modules/popouts";
import { defineComponent } from "vue";
import router from "@/router";
export default defineComponent({
  components: { CallTemplate, BigPreview },
  props: {
    participants: {
      type: Array as PropType<(CallParticipant & { user: User })[]>,
      required: true,
    },
  },
  data() {
    return {
      selecteduserId: this.participants[0].user.id,
    };
  },
  methods: {
    toggleMic() {
      voiceChannelModule.toggleMic();
    },
    settingsClicked() {
      router.push("/app/settings/call-options");
    },
    onCallClicked() {
      PopoutsModule.ShowPopout({
        id: "call-ip-leak-warning",
        component: "generic-popout",
        data: {
          callback: () => this.joinCall(),
          title: "P2P Calling Notice",
          description:
            "Because of the nature of P2P, your IP will be able to be seen by other participants on the call.",
        },
      });
    },
    async joinCall() {
      await leaveCall();
      voiceChannelModule.join({
        channelId: this.channelId,
      });
      joinCall(this.channelId);
    },

    async leaveCall() {
      await leaveCall();
      this.$emit("toggleExpand");
    },
    async shareScreen() {
      PopoutsModule.ShowPopout({
        id: "screenshare-popout",
        component: "ScreensharePopout",
      });
    },
  },
  computed: {
    emittingVoice() {
      return voiceChannelModule.audioStream;
    },
    selectedParticipant(): (CallParticipant & { user: User }) | undefined {
      return this.participants.find((p) => p.user.id === this.selecteduserId);
    },
    channelId(): string {
      return this.$route.params.channel_id as string;
    },
    isInCall(): boolean {
      return this.channelId === voiceChannelModule.joinedChannelId;
    },
  },
});
