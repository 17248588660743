
import { fetchMessages } from "@/services/messagesService";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { MessageLogStatesModule } from "@/store/modules/messageLogStates";
import { MessagesModule } from "@/store/modules/messages";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ScrollDownButton",
  computed: {
    hasNotification(): any {
      return LastSeenServerChannelsModule.serverChannelNotification(
        this.channelId
      );
    },
    channelId(): any {
      return this.$route.params.channel_id;
    },
  },
  methods: {
    ScrollDown() {
      const messageLogs = document.getElementById("messageLogs");
      if (!messageLogs) return;
      if (!MessageLogStatesModule.isBottomUnloaded(this.channelId)) {
        document.getElementById("messageLogs")?.scrollTo({
          behavior: "smooth",
          top: messageLogs.scrollHeight,
        });
        this.resetState();
        return;
      }
      // MessagesModule.SetChannelMessages({
      //   channelId: this.channelId,
      //   messages: null
      // });

      fetchMessages(this.channelId).then((json) => {
        MessagesModule.SetChannelMessages({
          channelId: this.channelId,
          messages: json.messages.reverse(),
        });
        this.$nextTick(() => {
          this.$nextTick(() => {
            document.getElementById("messageLogs")?.scrollTo({
              behavior: "smooth",
              top: messageLogs.scrollHeight,
            });
            this.resetState();
          });
        });
      });
    },
    resetState() {
      MessageLogStatesModule.UpdateState({
        channelId: this.channelId,
        state: {
          isScrolledDown: true,
          bottomUnloaded: undefined,
          scrollPosition: undefined,
        },
      });
    },
  },
});
