
import UploadQueueItem from "./UploadQueueItem.vue";
import { FileUploadModule } from "@/store/modules/fileUpload";

import { defineComponent } from "vue";
export default defineComponent({
  name: "UploadQueue",
  components: { UploadQueueItem },
  computed: {
    queueItems(): any {
      return FileUploadModule.uploadQueue;
    },
  },
});
