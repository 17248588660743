
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SuggestionPopoutTemplate",
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    items: {
      deep: true,
      handler: "onItemChange",
    },
  },
  methods: {
    up() {
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.items.length - 1;
        return;
      }
      this.selectedIndex -= 1;
    },
    down() {
      if (this.selectedIndex === this.items.length - 1) {
        this.selectedIndex = 0;
        return;
      }
      this.selectedIndex += 1;
    },
    enter() {
      this.$emit("selected", this.items[this.selectedIndex]);
    },
    onItemChange() {
      this.selectedIndex = 0;
    },
  },
});
