
import { defineComponent } from "vue";
export default defineComponent({
  name: "MessageBoxButtonTemplate",
  emits: ["click"],
  props: {
    icon: {
      type: String,
      required: true,
    },
    warn: {
      type: Boolean,
      required: false,
    },
  },
});
