
import emojiParser from "@/utils/emojiParser";
import { defineComponent } from "vue";
export default defineComponent({
  name: "EmojiSuggestionTemplate",
  props: {
    item: {
      type: Object as any,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    image(): any {
      if (this.item.unicode) {
        return emojiParser.replaceEmojis(this.item.unicode);
      }
      const image = new Image();
      image.classList.add("emoji");

      image.src = `${process.env.VUE_APP_NERTIVIA_CDN}emojis/${
        this.item.id
      }.${this.item.gif ? "gif" : "png"}`;
      return image.outerHTML;
    },
    name(): any {
      return this.item.name || this.item.shortcodes[0];
    },
  },
});
