
import User from "@/interfaces/User";
import {
  CallParticipant,
  voiceChannelModule,
} from "@/store/modules/voiceChannels";
import { PropType } from "vue";
import CallTemplate from "./SmallCallTemplate.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { CallTemplate },
  props: {
    participants: Array as PropType<(CallParticipant & { user: User })[]>,
  },
  computed: {
    allowExpand(): boolean {
      return (
        voiceChannelModule.joinedChannelId === this.$route.params.channel_id
      );
    },
  },
});
