
import User from "@/interfaces/User";
import { BotCommand } from "@/store/modules/botCommands";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "CommandSuggestionTemplate",
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object as PropType<{
        command: BotCommand;
        bot: User;
        argsEnteredLength: number;
      }>,
      required: true,
    },
  },
  computed: {
    commandInfo(): any {
      return this.item.command.a.split("//")[1];
    },
    args(): any {
      const regex = /\[(.*?)\]/gm;
      const argNames = this.item.command.a.match(regex);
      if (!argNames) return [];
      return argNames.map((v, i) => {
        return {
          name: v,
          typed: this.item.argsEnteredLength - 1 >= i,
        };
      });
    },
    isSelected(): any {
      if (this.args.find((a) => a.typed)) return false;
      return this.selected;
    },
  },
});
