
import User from "@/interfaces/User";
import { CallParticipant } from "@/store/modules/voiceChannels";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    participant: {
      type: Object as PropType<CallParticipant & { user: User }>,
      required: true,
    },
  },
});
