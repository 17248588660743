
import User from "@/interfaces/User";
import {
  CallParticipant,
  voiceChannelModule,
} from "@/store/modules/voiceChannels";
import { PropType } from "vue";
import Avatar from "@/components/AvatarImage.vue";
import { MeModule } from "@/store/modules/me";
import { defineComponent } from "vue";
export default defineComponent({
  components: { Avatar },
  props: {
    participant: {
      type: Object as PropType<CallParticipant & { user: User }>,
      required: true,
    },
  },
  watch: {
    videoStream: {
      immediate: true,
      handler(stream) {
        if (!stream) return;
        this.$nextTick(() => {
          const video = this.$refs.video as HTMLVideoElement;
          video.srcObject = stream;
          // console.log(this.videoStream?.getVideoTracks()[0].getSettings());
        });
      },
    },
  },
  computed: {
    isMyStream() {
      return MeModule.user.id === this.participant.user.id;
    },
    videoStream(): MediaStream | null | undefined {
      if (this.isMyStream) {
        const channelId = this.$route.params.channel_id;
        const joinedChannel = voiceChannelModule.joinedChannelId;
        if (joinedChannel !== channelId) return;
        return voiceChannelModule.videoStream;
      }
      return this.participant.videoStream;
    },
  },
});
