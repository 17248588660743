
import { permissions } from "@/constants/rolePermissions";
import Message from "@/interfaces/Message";
import { MeModule } from "@/store/modules/me";
import { MessagesModule } from "@/store/modules/messages";
import { PopoutsModule } from "@/store/modules/popouts";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { ServersModule } from "@/store/modules/servers";
import { defineComponent } from "vue";
import CustomButton from "../CustomButton.vue";
export default defineComponent({
  components: { CustomButton },
  data() {
    return {
      //
    };
  },
  methods: {
    onDeleteClicked() {
      if (this.selectedMessages.length === 1) {
        PopoutsModule.ShowPopout({
          id: "delete-message",
          component: "delete-message-popout",
          data: {
            messageID: this.selectedMessages[0],
            channelId: this.channelId,
            callback: this.onDeleted,
          },
        });
        return;
      }
      PopoutsModule.ShowPopout({
        id: "delete-message-batch",
        component: "delete-message-batch-popout",
      });
    },
    onDeleted() {
      this.deselectAll();
    },
    selectAll() {
      MessagesModule.messages[this.channelId].forEach((m) => {
        if (!m.messageID) return;
        if (!this.canDeleteMessage(m)) return;
        MessagesModule.selectMessage(m.messageID);
      });
    },
    deselectAll() {
      MessagesModule.deselectAll();
    },
  },
  computed: {
    selectedMessages() {
      return MessagesModule.selectedMessageIds;
    },
    canDeleteMessage(): any {
      return (message: Message) => {
        if (!message.messageID) return false;
        if (message.localMessage) return false;
        if (this.messageCreatedByMe(message)) return true;
        if (!this.serverID) return false;
        if (this.isServerOwner) return true;
        if (!MeModule.user.id) return false;
        return ServerMembersModule.memberHasPermission(
          MeModule.user.id,
          this.serverID,
          permissions.ADMIN.value
        );
      };
    },
    messageCreatedByMe(): any {
      return (message: Message) => MeModule.user.id === message.creator.id;
    },
    isServerOwner(): any {
      if (!this.serverID) return false;
      const server = ServersModule.servers[this.serverID];
      return MeModule.user.id === server.creator.id;
    },
    serverID(): any {
      if (this.currentTab !== "servers") return undefined;
      return this.$route.params.server_id;
    },
    currentTab(): any {
      return this.$route.path.split("/")[2];
    },
    channelId(): string {
      return this.$route.params.channel_id as string;
    },
  },
});
