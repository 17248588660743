
import Channel from "@/interfaces/Channel";
import { emojiURL } from "@/utils/emojiParser";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChannelSuggestionTemplate",
  props: {
    item: {
      type: Object as PropType<Channel>,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    iconURL(): any {
      const icon = this.item.icon;
      if (!icon) return null;
      const isCustom = icon.startsWith("g_") || icon.startsWith("c_");
      const isGif = icon.startsWith("g_");
      const customEmojiID = icon.split("_")[1];
      return emojiURL(isCustom ? customEmojiID : icon, { isCustom, isGif });
    },
    channelStyle(): any {
      return {
        "--icon-url": this.iconURL && `url("${this.iconURL}")`,
      };
    },
  },
});
