
import { ChannelsModule } from "@/store/modules/channels";
import { ServersModule } from "@/store/modules/servers";
import fileSize from "filesize";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "UploadQueueItem",
  props: {
    item: {
      type: Object as PropType<{
        file: File;
        uploading: boolean;
        progress: number;
        channelId: string;
      }>,
      required: true,
    },
  },
  computed: {
    sizeLabel(): any {
      return fileSize(this.item.file.size);
    },
    channelName(): any {
      if (this.channel.name) {
        const serverName =
          ServersModule.servers[this.channel.server_id || ""].name;
        return serverName + "#" + this.channel.name;
      }
      const username =
        "@" + this.DMChannel?.recipients?.[0].username || "Unknown";
      return username;
    },
    DMChannel(): any {
      return ChannelsModule.getDMChannel(this.item.channelId);
    },
    channel(): any {
      return ChannelsModule.channels[this.item.channelId];
    },
  },
});
