
import { FileUploadModule } from "@/store/modules/fileUpload";
import fileSize from "filesize";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  computed: {
    name(): any {
      return this.file?.name;
    },
    sizeLabel(): any {
      return fileSize(this.size);
    },
    size(): any {
      return this.file?.size || 0;
    },
    file(): any {
      return FileUploadModule.file.file;
    },
  },
});
