
import {
  CallParticipant,
  voiceChannelModule,
} from "@/store/modules/voiceChannels";
import { PropType } from "vue";
import SmallCallPreview from "./SmallCallPreview.vue";
import ExpandedCallPreview from "./ExpandedCallPreview.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { SmallCallPreview, ExpandedCallPreview },
  props: {
    participants: Array as PropType<CallParticipant[]>,
  },
  data() {
    return {
      expanded: localStorage["call-preview-expanded"] === "true",
    };
  },
  methods: {
    setExpanded(state: boolean) {
      localStorage["call-preview-expanded"] = state;
      this.expanded = state;
    },
    toggleExpand() {
      this.setExpanded(!this.expanded);
    },
  },
  watch: {
    joinedVoiceChannelId: {
      handler(id) {
        if (id) {
          this.setExpanded(true);
        } else {
          this.setExpanded(false);
        }
      },
      immediate: true,
    },
  },
  computed: {
    joinedVoiceChannelId(): string | null {
      return voiceChannelModule.joinedChannelId;
    },
  },
});
