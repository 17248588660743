
import { MessagesModule } from "@/store/modules/messages";

import { defineComponent } from "vue";
export default defineComponent({
  name: "EditPanel",
  props: {
    messageID: {
      type: String,
      required: true,
    },
  },
  computed: {
    isImage(): any {
      return this.message?.files?.[0]?.dimensions;
    },
    isFile(): any {
      return this.message?.files?.[0];
    },
    message(): any {
      return MessagesModule.channelMessages(
        this.$route.params.channel_id as string
      ).find((m) => m.messageID === this.messageID);
    },
  },
  watch: {
    message: {
      handler: "onMessageChange",
    },
  },
  methods: {
    onMessageChange() {
      if (!this.message) {
        this.$emit("close");
      }
    },
  },
});
