
import { FileUploadModule } from "@/store/modules/fileUpload";
import fileSize from "filesize";
import CheckBox from "@/components/CheckBox.vue";
import RadioBox from "@/components/RadioBox.vue";
import { MeModule } from "@/store/modules/me";
import { PopoutsModule } from "@/store/modules/popouts";
import { useWindowProperties } from "@/utils/windowProperties";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  components: { CheckBox, RadioBox },
  data() {
    return {
      messageLogsEl: null as HTMLElement | null,
      width: "450px",
    };
  },
  computed: {
    file(): File | undefined {
      return FileUploadModule.file.file;
    },
    exceedCDNMaxSize(): any {
      const maxCDNSize = 7340000;
      return (this.file?.size || 0) > maxCDNSize;
    },
    name(): any {
      return this.file?.name;
    },
    sizeLabel(): any {
      return fileSize(this.size);
    },
    size(): any {
      return this.file?.size || 0;
    },
    compress: {
      get(): any {
        return FileUploadModule.compress;
      },
      set(val: boolean) {
        FileUploadModule.SetCompress(val);
      },
    },
    cdn: {
      get(): any {
        return FileUploadModule.cdn;
      },
      set(val: number) {
        FileUploadModule.SetCDN(val);
      },
    },
    parentWidth(): any {
      // this line is needed to make this getter reactive.
      const windowWidth = useWindowProperties().resizeWidth;

      return this.messageLogsEl?.clientWidth || 0;
    },
  },
  watch: {
    parentWidth: {
      handler: "onParentWidthChange",
    },
  },
  mounted() {
    // set image preview
    const reader = new FileReader();
    const image = this.$refs.image as any;
    reader.onloadend = function () {
      image.style.backgroundImage = `url(${reader.result})`;
    };
    if (this.file) {
      reader.readAsDataURL(this.file);
    }
    this.messageLogsEl = document.getElementById("messageLogs");
  },
  methods: {
    onToggleCompress(val: boolean) {
      if (!val && this.exceedCDNMaxSize && this.cdn === 1) {
        alert(
          "Nertivia CDN Max file size: 7MB. \nEither compress the image or upload using Google Drive option."
        );
        FileUploadModule.SetCompress(true);
      }
    },
    onRadioIndexChange(val: number) {
      if (val === 0 && !MeModule.user.googleDriveLinked) {
        this.cdn = 1;
        PopoutsModule.ShowPopout({
          id: "link-google-drive",
          component: "LinkGoogleDrive",
        });
        return;
      }
      if (val === 1 && this.exceedCDNMaxSize) {
        FileUploadModule.SetCompress(true);
      }
    },
    onParentWidthChange() {
      if (!this.parentWidth) return;
      if (this.parentWidth < 470) {
        this.width = this.parentWidth - 15 + "px";
        return;
      }
      this.width = "450px";
    },
  },
});
